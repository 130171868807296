<template>
  <div class="d-flex justify-center mb-6">
    <div
      v-if="wholePageLoad"
      class="d-flex h-100 justify-center align-items-center"
      style="min-height:50vh;"
    >
      <v-progress-circular
        indeterminate
        color="black"
        size="50"
      ></v-progress-circular>
    </div>
    <v-card v-else elevation="0" style="width: 98%; max-width: 1000px;">
      <v-btn class="ma-2" outlined @click="goBack">
        <span style="font-size: 1.2rem" class="ml-2 mb-1">
          رجوع
        </span>
        <v-icon>mdi-backburger</v-icon>
      </v-btn>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="fields.name"
          :rules="nameRules"
          label="التسمية"
          required
        ></v-text-field>

        <v-btn class="mr-4" @click="goBack" depressed outlined large>
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            إلغاء
          </span>
          <v-icon>cancel</v-icon>
        </v-btn>

        <v-btn
          :disabled="!valid"
          class="mr-4"
          @click="submit"
          depressed
          outlined
          large
        >
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            حفظ
          </span>
          <v-icon>save</v-icon>
        </v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    valid: true,
    fields: { name: "" },
    nameRules: [(v) => !!v || "Name is required"],
    wholePageLoad: true,
  }),

  methods: {
    ...mapActions("authors", ["getAuthor", "updateAuthor"]),
    submit() {
      const { id } = this.$route.params;

      this.$confirm({
        message: `هل أنت متأكد(ة)؟ لا يمكن التراجع عن هذه الخطوة`,
        button: {
          no: "لا",
          yes: "نعم",
        },
        callback: (confirm) => {
          if (confirm) {
            this.wholePageLoad = true;

            this.updateAuthor({ id, ...this.fields }).then(() => {
              this.wholePageLoad = false;

              // this.$router.push({ name: "BackendAuthor" });
            });
          }
        },
      });
    },
    goBack() {
      this.$router.push({ name: "BackendAuthor" });
    },
  },
  mounted() {
    const { id } = this.$route.params;
    this.getAuthor(id).then(({ name }) => {
      this.fields.name = name;
      this.wholePageLoad = false;
    });
  },
};
</script>
